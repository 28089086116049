import React, { useState, useEffect } from 'react';
import { useMedia } from 'use-media';
import * as styles from './shop.module.css';

import Breadcrumbs from '../components/Breadcrumbs';
import CardController from '../components/CardController';
import Container from '../components/Container';
import Chip from '../components/Chip';
import Icon from '../components/Icons/Icon';
import Layout from '../components/Layout';
import LayoutOption from '../components/LayoutOption';
import ProductCardGrid from '../components/ProductCardGrid';
import Config from '../config.json';
import { navigate } from 'gatsby';
import { Spacer } from '../uiBook';
import { useAppContext } from '../context/useAppContext';

const ShopPage = (props) => {
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [searchParams, setSearchParams] = useState([]);

  const isMobile = useMedia({ maxWidth: '460px' });

  const { productsData, fetchData } = useAppContext();

  useEffect(() => {
    window.addEventListener('keydown', escapeHandler);

    fetchData();
    return () => window.removeEventListener('keydown', escapeHandler);
  }, []);

  useEffect(() => {
    if (productsData?.length) {
      if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search);
        const category = params.getAll('category');
        setSearchParams(category);
        let array = productsData?.filter(
          (product) => product.status === 'active'
        );
        const catArray = category?.map((cat) => cat.toLowerCase());
        if (!catArray?.length) return setData(array);
        const filteredArray =
          array?.filter(
            (product) =>
              catArray.includes(product.category) ||
              catArray.includes(product.category2)
          ) || [];
        setData(filteredArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    typeof window !== 'undefined' ? window.location.search : '',
    productsData,
  ]);

  const escapeHandler = (e) => {
    if (e?.keyCode === undefined) return;
    if (e.keyCode === 27) setShowFilter(false);
  };

  const updateQueryParam = (param, value, action) => {
    let newParams;
    if (action === 'remove') {
      newParams = searchParams.filter((p) => p !== value);
    }
    if (action === 'add') {
      newParams = [...searchParams, value];
    }
    const newParamString = newParams?.map((n) => `${param}=${n}`).join('&');
    const currentUrl = new URL(window.location);
    const newSearchParams = newParamString;

    if (!newSearchParams && action === 'remove') {
      navigate(`${currentUrl.pathname}`, {
        replace: true,
      });
    } else {
      navigate(`${currentUrl.pathname}?${newSearchParams}`, {
        replace: true,
      });
    }
  };

  const handleCloseChip = (label) => {
    updateQueryParam('category', label, 'remove');
  };

  const handleFilterChange = (param, value) => {
    const label = Config.filters[0]?.items[value]?.name;
    const newValue = !Config.filters[0]?.items[value]?.value;
    if (!newValue) {
      updateQueryParam('category', label, 'remove');
    } else {
      updateQueryParam('category', label, 'add');
    }
  };

  return (
    <Layout>
      <Spacer height={48} />
      <div className={styles.root}>
        <Container size={'large'} spacing={'min'}>
          <div className={styles.breadcrumbContainer}>
            {!isMobile && (
              <Breadcrumbs
                crumbs={[
                  { link: '/', label: 'Home' },
                  { link: '/shop', label: 'Items' },
                ]}
              />
            )}
          </div>
        </Container>
        {/* <Banner
          maxWidth={'650px'}
          name={`Woman's Sweaters`}
          subtitle={
            'Look to our women’s sweaters for modern takes on one-and-done dressing. From midis in bold prints to dramatic floor-sweeping styles and easy all-in-ones, our edit covers every mood.'
          }
        /> */}
        <Container size={'large'} spacing={'min'}>
          <div className={styles.metaContainer}>
            <span className={styles.itemCount}>{data.length} items</span>
            <div className={styles.controllerContainer}>
              <div
                className={styles.iconContainer}
                role={'presentation'}
                onClick={() => setShowFilter(!showFilter)}
              >
                <Icon symbol={'filter'} />
                <span>Filters</span>
              </div>
              {/* <div
                className={`${styles.iconContainer} ${styles.sortContainer}`}
              >
                <span>Sort by</span>
                <Icon symbol={'caret'} />
              </div> */}
            </div>
          </div>
          <CardController
            closeFilter={() => setShowFilter(false)}
            visible={showFilter}
            filters={Config.filters}
            onChange={handleFilterChange}
          />
          <div className={styles.chipsContainer}>
            {searchParams?.map((label, index) => (
              <div key={index}>
                <Chip name={label} close={() => handleCloseChip(label)} />
              </div>
            ))}
          </div>
          <div className={styles.productContainer}>
            <span className={styles.mobileItemCount}>{data?.length} items</span>
            <ProductCardGrid data={data}></ProductCardGrid>
          </div>
          {/* <div className={styles.loadMoreContainer}>
            <span>6 of 456</span>
            <Button fullWidth level={'secondary'}>
              LOAD MORE
            </Button>
          </div> */}
        </Container>
      </div>

      <LayoutOption />
    </Layout>
  );
};

export default ShopPage;
